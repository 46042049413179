<template>
  <div class="static-content-wrapper">
    <h1>Support</h1>
    <p>
      We are here for you and appreciate all the help you can provide to us
      during our BETA release. If you find anything that does not seem to be
      working right, need support, or you would like to suggest a feature,
      please
      <a class="secondary" href="mailto:gkruel@me.com" target="_blank"
        >send us an email</a
      >
      and we will get back to you as soon as we can.
    </p>
  </div>
</template>
